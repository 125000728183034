// Clouds
import cloud0 from './cloud0.png'
import cloud1 from './cloud1.png'
import cloud2 from './cloud2.png'
import cloud3 from './cloud3.png'
import cloud4 from './cloud4.png'
import cloud5 from './cloud5.png'
import cloud6 from './cloud6.png'
import cloud7 from './cloud7.png'
import cloud8 from './cloud8.png'
import cloud9 from './cloud9.png'

export const cloudAssets = [
  cloud0,
  cloud1,
  cloud2,
  cloud3,
  cloud4,
  cloud5,
  cloud6,
  cloud7,
  cloud8,
  cloud9
]
